import React, { useEffect, useState } from "react"
import Image from "../../elements/image";
import Slideshow from "../../layout/slideshowNew";
import style from "./roseline.module.scss"

import { SwiperSlide } from 'swiper/react';

const Roseline = ({ content }) => {

    const params = {
        loop: true,
        loopedSlides: 4,
        mode: 'horizontal',
        slidesPerView: "auto",
        // slidesPerView: 2,
        // mousewheel: true,
        // activeSlideKey: 0,
        // watchSlidesVisibility: true,
        lazy: false,
        autoplay: {
          delay: 1000
        },
        navigation: {
          nextEl: style.swiperButtonNext,
          prevEl: style.swiperButtonPrev
        },
        // renderPrevButton: () => <div role="button" className="swiper-button-prev">Prev</div>,
        // renderNextButton: () => <div role="button" className="swiper-button-next">Next</div>,
      
        freeMode: true,
        shouldSwiperUpdate: true,
    }

   


    console.log(content);

    const images = content.roseline_sculptures
    const backgrounds = content.roseline_backgrounds

    const [ state, setState ] = useState({
        activeIndex: 0
    });

    let interval;

    useEffect(() => {

        console.log(backgrounds, state.activeIndex)

        interval = setTimeout(() => {

            const newActiveIndex = state.activeIndex < backgrounds.length-1 ? state.activeIndex + 1 : 0

            setState({
                activeIndex: newActiveIndex
            })
        }, 5000)

        return () => {
            clearTimeout(interval);
        }
    }, [state])

    return(
        <div className={style.roseline}>
            <div className={style.background}>
                <img src={backgrounds[state.activeIndex].filename} />
            </div>
            <div className={style.wrapper}>
            <Slideshow params={params} slidesPerView={1}>
            {
                images.map((image, i) => (
                <SwiperSlide key={i} className={style.image}>
                    <Image src={image.filename} />
                </SwiperSlide>
                ))
            }
            </Slideshow>
           {/* {
               images.map((item, i) => (
                   <img key={i} src={item.filename} alt="" />
               ))
           } */}
           </div>
        </div>
    )
}

export default Roseline
